import React, { useState, useRef, useEffect } from 'react';
import { DataTable, DataTableExpandedRows, DataTableValueArray } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import formServices from '../../services/formServices'; // Importing the services
import AddFormPopup from './AddFormPopup';
import ViewFormPopup from './ViewFormPopup';
// Define the Form interface to type the data correctly
interface Form {
  p_formid: number;
  p_formname: string;
  p_description: string;
  p_status: boolean;
}

const FormTable = (props: any) => {
  const [selectedForm, setSelectedForm] = useState<Form[]>([]);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [addNewFormPopup, setAddNewFormPopup] = useState(false);
  const [viewFormPopup, setViewFormPopup] = useState<number>(0);
  const [editForm, setEditForm] = useState<Form | null>(null); // Correctly type the editForm state
  const [formToView, setFormToView] = useState<Form | null>(null);
  const toast = useRef<Toast>(null);
  const [totalRecordsForm, setTotalRecordsForm] = useState<number>(0);
  const [isView,setIsView]=useState(false);
  const[formId,setFormId]=useState<number>(0);  
  const [reloadFormGrid, setReloadFormGrid] = useState(false);


  // Function to handle "View" button click
  const handleViewClick = (rowData: Form) => {
    setFormToView(rowData); // Set the selected form to be viewed
    setViewFormPopup(rowData.p_formid); // Show the view form popup
  };

  // Function to handle "Edit" button click
  const EditForm = async (rowData: Form,view:boolean) => {
    setLoading(true);
    const data ={ 
      formid:formId
    }

    await formServices.EditForm(rowData.p_formid)
      .then((res: any) => {
        if (res.status==200) {
          GetForm();
          let formFields=Object.keys(res.data).map(ele=>{
            return { formField: res.data[ele].formfield, fieldType: res.data[ele].fieldtype!='textbox'?res.data[ele].fieldtype:'text', label:res.data[ele].fieldlabel , value: res.data[ele].fieldlabel, options: res.data[ele][res.data[ele].fieldtype] || []  }
          })
          
          let data= {
            p_formname: rowData.p_formname,
            p_description: rowData.p_description,
            p_formfields: formFields, // Attach the JSON string here
            p_status: rowData.p_status,
            p_formid: rowData.p_formid,
            view
          };
          props.onEditFormClicked(data);
        } else {
          toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
        }
        
        setLoading(false);
        console.log(res.data)
        console.log(rowData)
      })
      .catch((error) => {
        setLoading(false);
        console.error("Delete error:", error);
        toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  
  
  };

  const onViewClick=(data:Form)=>{
    const formId = { formid: data };
    setIsView(true);
    setAddNewFormPopup(false);
   setFormId(data.p_formid);
   handleViewClick(data);
    console.log(data.p_formid);
    console.log('view is call',isView)
  }
  const onEditClick=(data:any)=>{
    console.log('view is call',isView)

    setIsView(false);
    EditForm(data,true);
  }


const onDeleteClick = async (data: Form) => {
  const formId = { formid: data.p_formid };
  confirmDialog({
    message: `Are you sure you want to delete this form?`,
    header: "Confirm Delete",
    icon: "pi pi-exclamation-triangle",
    accept: () => onFormDelete(formId),
  });
};

const onFormDelete = async (formId: any) => {
  setLoading(true);
  await formServices.DeleteForm(formId)
    .then((res: any) => {
      if (res.data.p_response) {
        toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
        GetForm(); // Refresh the form data
      } else {
        toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
        setLoading(false);
      }
    })
    .catch((error) => {
      setLoading(false);
      console.error("DeleteForm error:", error);
      toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
    });
};

  const verifiedBodyTemplate = (rowData: Form) => {
    return (
      <>
        <Button
          className="table-button custom-icon p-0"
          tooltip="View"
          onClick={() => onViewClick(rowData)} // Edit button click
        >
          <i className="pi pi-eye" style={{ color: "#e0a553" }}></i>
        </Button>

        <Button
          className="table-button custome-icon edit-icon"
          onClick={() => EditForm(rowData,false)} // Edit button click
          tooltip="Edit"
        />
        <Button
          className="table-button custome-icon delete-icon"
          onClick={() => onDeleteClick(rowData)} // Delete button click
          tooltip="Delete"
        />
      </>
    );
  };

  const onRowExpand = (event: any) => {
    setExpandedRows(event.data);
  };

  const rowExpansionTemplate = (data: Form) => {
    return (
      <div className="p-3">
        <h5>Details for {data.p_formname}</h5>
        <p>{data.p_description}</p>
      </div>
    );
  };

  useEffect(() => {
    setLoading(true);
    GetForm(null); // Fetch all forms on initial load
  }, []);

  useEffect(() => {
    GetForm();
  }, [props.reloadFormGrid]);

  const GetForm = (formid: number | null = null) => {
    setLoading(true);

    // If formid is null, use 0 for "all forms"
    const finalFormId = formid === null ? 0 : formid;

    formServices.GetForm(finalFormId) // Call API with valid formid or 0
      .then((res: any) => {
        if (res.status === 200) {
          setSelectedForm(res.data);
          setTotalRecordsForm(res.data.length);
          props.setFormDataFilter(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to fetch forms", life: 3000 });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("GetForm error:", error);
        toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };

  return (
    <div className="table-section">
      <Toast ref={toast} />
      <DataTable
        value={props.filteredFormData}
        dataKey="p_formid"
        scrollHeight="600px"
        paginator rows={15}
        loading={loading}
        emptyMessage="No forms found."
        onRowExpand={onRowExpand}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column field="srNo" header="Sr. No." style={{ minWidth: "3rem" }} body={(rowData, index) => <span>{index.rowIndex + 1}</span>} />
        <Column field="p_formname" header="Form Name" style={{ minWidth: "12rem" }} sortable />
        <Column field="p_description" header="Description" style={{ minWidth: "12rem" }} sortable />
        <Column field="p_status" header="Status" body={(rowData: Form) => <span>{rowData.p_status ? "Active" : "Inactive"}</span>} sortable />
        <Column field="Action" header="Action" style={{ minWidth: "6rem" }} body={verifiedBodyTemplate} />
      </DataTable>

      {addNewFormPopup && (
        <AddFormPopup
          formData={editForm} // Pass null for new or data for editing
          visible={addNewFormPopup}
          setVisible={setAddNewFormPopup}
          setReloadFormGrid={setReloadFormGrid}
          toast={toast} // For toast notifications
          isView={isView}
        />
      )}

{
        isView &&(<ViewFormPopup
          visible={isView}
          setVisible={setAddNewFormPopup} formId={formId}
          setisView={setIsView}
          />
          )
      }

    </div>
  );
};

export default FormTable;
