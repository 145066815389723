let enStrings = {
  treatment: "Treatment",
  addEmployee: "Add Employee",
  request: "Request",
  employeeDetails: "Employee details",
  addUser: "Add User",
  firstName: "First name",
  lastName: "Last name",
  mobileNumber: "Mobile number",
  emailId: "Email Id",
  dateOfBirth: "Date of Birth",
  dateOfJoining: "Date of joining",
  dateOfRelease: "Date of release",
  country: "Country",
  state: "State",
  city: "City",
  pinCode: "Pin Code",
  adharCard: "Adhar Card",
  address: "Address",
  married: "Married",
  save: "Save",
  cancel: "Cancel",
  areaName: "Area name",
  area: "Area",
  areaDetails: "Area details",
  addNewArea: "Add new area",
  addNewSubArea: "Add new subarea",
  description: "Description",
  subAreaName: "Subarea",
  categoryName: "Category",
  subAreaDetails: "Subarea details",
  status: "Status",
  assignManager: "Assign manager",
  poi: "POI details",
  addNewPOI: "Add new POI",
  poiName: "POI name",
  poiCode: "POI code",
  capacity: "Capacity",
  MasterCapacity: "Master capacity",
  uploadImage: "Upload image",
  select: "Select",
  uploadIcon: "Upload icon",
  lat: "Latitude",
  long: "Longitude",
  addNewEmployee: "Add new employee",
  middleName: "Middle name",
  companyAssignedMobileNo: "Company assigned mobile no",
  employeeDetailsCreateUpdate: "Employee (Create / Update)",
  AddNewArea: "Area (Create / Update)",
  role: "Role",
  addsubareaname: "Subarea name",
  addNewSubAreaHeader: "Subarea (Create / Update)",
  poiDetails: "POI (Create / Update)",
  CMS: "CMS",
  employeeId: "Employee Id",
  submit: "Submit",
  isMarried: "Is Married",
  profile: "Profile",
  selectUserRole: "Select User Role",
  dashboard: "Dashboard",
  assignPermissions: "Assign Permissions",
  users: "Users",
  visitorsDetails: "Visitors details",
  protocol: "Protocol",
  settings: "Settings",
  selectarole: "Select a role",
  selectActivity: "Select an activity",
  selectTherapy: "Select an therapy",
  subArea: "Sub Area",
  customerDetails: "Customer details",
  addTreatMent: "Add treatment",
  treatmentHistory: "Treatment History",
  dateOfTreatment: "Date of treatment",
  typeOfTreatment: "Type of treatment",
  addCharacteristics: "Add characteristics",
  activity: "Activity",
  therapy: "Therapy",
  subCapacity: "Sub capacity",
  changePassword: "Change password",
  addNewActivity: "Add new activity",
  addNewForm: "Add new form",
  addNewTherapy: "Add new therapy",
  addNewActivityHeader: "Activity (Create / Update)",
  addNewTherapyHeader: "Therapy (Create / Update)",
  duration: "Duration",
  activityName: "Activity name",
  therapyName: "Therapy name",
  activityDetails: "Activity details",
  formDetails: "Form details",
  therapyDetails: "Therapy details",
  addCustomTab: "Add custom tab",
  tabName: "Tab name",
  addParameteres: "Add parameters",
  expertiesDetails: "Expertise details",
  addExpert: "Add expert",
  expertPanel: "Expert panel",
  experties: "Expertise",
  assignExpert: "Assign expert",
  assignActivity: "Assign activity",
  assignTherapy: "Assign therapy",
  hours: "Hours",
  minutes: "Minutes",
  seconds: "Seconds",
  form: "Form",
  medicinename: "Medicine name",
  dose: "Dose",
  days: "Days",
  startdate: "Start date",
  enddate: "End date",
  frequency: "Timing for doses",
  does: "Do's",
  donts: "Don'ts",
  prescription: "Prescription",
  pointofInterest: "Point of Interest",
  date: "Date",
  time: "Time",
  video: "Video",
  audio: "Audio",
  situmSetting:"Situm Setting",
  baseUrl:"Base url",
  apiKey:"Api key",
  buildingId:"Building id",
  floorId:"Floor id",
  syncPoiData:"POI synchronization",
  situmSettings:"Situm settings",
  situmSettingUpdate:"Update situm setting",
  actionedbyname:"Last updated by",
  actionedon:"Last Updated on",
  syncPOiData:"Sync poi",
  staying:"Staying",
  sequence:"Sequence",
  todaysVisitorreport:"Todays visitor's report",
  userActivitylogreport:"User activity log report",
  addPrescription:"Add prescription",
  dateOfPrescription:"Date of prescription"
};

export default enStrings;
