import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import GetAllCustomerService from "../../services/getallcustomer";
import moment from "moment";
import { contextStore } from "../../context/CustomStateProvider";
import { Button } from "primereact/button";
import ProtocolHistory from "./ProtocolHistory";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { Toast } from "primereact/toast";
import AddFormPopup from "./AddFormPopup";
import ViewFormPopup from "./ViewDetailsPopup"; // Import the new ViewFormPopup component

interface Customer {
  srNo: number;
  p_Dob: any;
  p_FirstName: string;
  p_LastName: string;
  p_MobileNo: any;
  p_UserId: number;
  p_email: any;
}

const Protocol = () => {
  const [customerdata, setCustomerData] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [viewTreatMentData, setViewTreatMentData] = useState<any>();
  const [viewHistory, setViewHistory] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showViewPopup, setShowViewPopup] = useState(false); // State for viewing details
  const [selectedForm, setSelectedForm] = useState<Customer | null>(null);
  const toast = useRef<Toast>(null);
  const [customerid, setcustomerid] = useState(false);

  useEffect(() => {
    ShowAllCustomers();
  }, []);

  const filteredData = useMemo(() => {
    if (searchQuery) {
      const lowercasedFilter = searchQuery.toLowerCase();
      return customerdata.filter(customer =>
        Object.values(customer).some(value =>
          String(value).toLowerCase().includes(lowercasedFilter)
        )
      );
    }
    return customerdata;
  }, [searchQuery, customerdata]);

  const ShowAllCustomers = () => {
    setLoading(true);
    GetAllCustomerService.GetAllCustomers(null).then((res: any) => {
      try {
        if (res.status === 200) {
          setCustomerData(res.data);
          setLoading(false);
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setLoading(false);
        }
      }
    });
  };

  const SrNo = (rowData: any, index: any) => (
    <div className="flex align-items-center gap-2">
      <span>{index.rowIndex + 1}</span>
    </div>
  );

  const DateOfbirth = (rowData: any) => {
    const dateOfBirth = rowData.p_Dob ? moment(rowData.p_Dob).format("DD/MM/YYYY") : "";
    return (
      <div className="flex align-items-center gap-2">
        <span>{dateOfBirth}</span>
      </div>
    );
  };

  const onViewTreatMent = (rowData: any) => {
    setViewTreatMentData(rowData);
    setViewHistory(true);
  };

  const verifiedBodyTemplate = (rowData: Customer) => (
    <Button className="table-button green-btn" label="View treatment" onClick={() => onViewTreatMent(rowData)} />
  );

  const onEditForm = (rowData: any) => {
    setcustomerid(rowData.p_CustomerId)
    setSelectedForm(rowData);
    setShowPopup(true);
  };

  const onViewDetails = (rowData: any) => {
    setcustomerid(rowData.p_CustomerId);
    setSelectedForm(rowData);
    setShowViewPopup(true); // Show view details popup
  };

  const formsBodyTemplate = (rowData: any) => (
    <React.Fragment>
      <Button className="table-button custome-icon editform-icon" tooltip="Edit" onClick={() => onEditForm(rowData)} />
      <Button
        className="table-button custom-icon"
        tooltip="View"
        onClick={() => onViewDetails(rowData)} 
      >
        <i className="pi pi-eye" style={{ color: "#e0a553" }}></i>
      </Button>
    </React.Fragment>
  );

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleFormSubmit = (formData: any) => {
    console.log("Form submitted with data:", formData);

    if (selectedForm) {
      const updatedData = customerdata.map(customer =>
        customer.p_UserId === selectedForm.p_UserId ? { ...customer, ...formData } : customer
      );
      setCustomerData(updatedData);
    } else {
      // Optionally handle adding new customer data
      setCustomerData(prev => [...prev, { ...formData, p_UserId: Date.now() }]); 
    }

    setShowPopup(false);
  };

  return (
    <div className="container">
      <Toast ref={toast} />
      {!viewHistory ? (
        <>
          <HeaderWithBackButton title={resource.customerDetails} controls={[]} handleSearch={handleSearch} searchQuery={searchQuery} />
          <div className="table-section">
            <DataTable value={filteredData} dataKey="id" scrollable scrollHeight="600px" loading={loading} emptyMessage="No POI found." paginator rows={15}>
              <Column field="srNo" header="Sr. No." style={{ minWidth: "3rem" }} body={SrNo} />
              <Column field="p_FirstName" header="First Name" sortable />
              <Column field="p_LastName" header="Last Name" sortable />
              <Column field="Forms" header="Forms" style={{ minWidth: "2rem" }} body={formsBodyTemplate} />
              <Column field="p_email" header="Email" sortable />
              <Column field="p_MobileNo" header="Mobile No." sortable />
              <Column field="p_Dob" header="DOB" body={DateOfbirth} sortable />
              <Column field="Action" header="Action" style={{ minWidth: "2rem" }} body={verifiedBodyTemplate} />
              
            </DataTable>
          </div>
        </>
      ) : (
        <>
          <ProtocolHistory viewTreatMentData={viewTreatMentData} toast={toast} setViewHistory={viewHistory} setViewHistoryfunction={setViewHistory} />
        </>
      )}

      {/* AddFormPopup component for editing forms */}
      {showPopup && (
        <AddFormPopup
          visible={showPopup}
          onHide={() => setShowPopup(false)}
          formData={selectedForm}
          title="Form(Submit/Update)"
          onSubmit={handleFormSubmit} // Pass the submit handler
          customerid = {customerid}
          toast={toast}
        />
      )}
        
      {/* ViewFormPopup component for viewing submitted form details */}
      {showViewPopup && selectedForm && (
        <ViewFormPopup
          visible={showViewPopup}
          onHide={() => setShowViewPopup(false)}
          title="View Form Details"
          customerid = {customerid}
        />
      )}
      
    </div>
  );
};

export default Protocol;
