import axios from "axios";
import authHeader from "./authHeader";

// Define the Form type
export interface Form {
  p_formid: number;
  p_formname: string;
  p_description: string;
  p_isactive: boolean;
}

// Function to fetch form details for editing by formid
const EditForm = async (formid: number) => {
  try {
    // Request to fetch form details by ID
    const response = await axios.post<{ data: Form }>(
      `${process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT}api/form/getformdetailsbyid`,
      {
        formid, // Send formid directly (not wrapped in an object)
      },
      {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(), // Auth header for security
        },
      }
    );

    // Return the 'Form' data directly (response.data.data is expected to be a 'Form')
    return response; // This contains the form object
  } catch (error) {
    console.error("Error fetching form details:", error);
    throw error; // Rethrow the error to be handled in the component
  }
};

// Function to fetch all forms (if needed)
const GetForm = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/form/getallform`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Function to delete a form
const DeleteForm = async (data: any) => {
  try {
    const response = await axios.delete(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/form/deleteCreatedForm`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      data: {
        formid: data.formid,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Add or update form attributes
const AddForm = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/form/addorupdateformsattributes`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Function to update form attributes
const UpdateForm = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/form/addorupdateformsattributes`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Export the service functions
const formServices = {
  EditForm,
  GetForm,
  AddForm,
  DeleteForm,
  UpdateForm,
};

export default formServices;
