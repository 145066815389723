import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import FormService from "../../services/FormService";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner"; // Import the ProgressSpinner

interface DynamicFieldsApiResponse {
  [key: string]: {
    value: string;
    formfield: string;
  };
}

interface FormResponse {
  formname: string;
  submittedvalues: string;
}

interface ViewFormPopupProps {
  visible: boolean;
  onHide: () => void;
  title: string;
  customerid: any;
}

const ViewDetailsPopup: React.FC<ViewFormPopupProps> = ({
  visible,
  onHide,
  title,
  customerid,
}) => {
  const [forms, setForms] = useState<FormResponse[]>([]);
  const [selectedForm, setSelectedForm] = useState<FormResponse | null>(null);
  const [dynamicFields, setDynamicFields] = useState<DynamicFieldsApiResponse>({});
  const [loadingForms, setLoadingForms] = useState<boolean>(true); // Loading state for fetching forms
  const [loadingDynamicFields, setLoadingDynamicFields] = useState<boolean>(true); // Loader state for dynamic fields

  useEffect(() => {
    if (visible) {
      fetchSubmittedFormDetails(customerid);
    }
  }, [visible]);

  const fetchSubmittedFormDetails = async (customerid: any) => {
    try {
      setLoadingForms(true);
      const response = await FormService.getSubmittedFormById({ customerid });
      const typedResponse = response as { status: number; data: FormResponse[] };

      if (typedResponse.status === 200) {
        setForms(typedResponse.data);
      } else {
        console.error("Failed to fetch submitted form details", response);
      }
    } catch (error) {
      console.error("Error fetching submitted form details", error);
    } finally {
      setLoadingForms(false); // Hide loader after fetching forms
    }
  };

  const onFormSelect = (form: FormResponse) => {
    setSelectedForm(form);
    setLoadingDynamicFields(true); // Set loader to true before fetching dynamic fields
    try {
      const parsedValues = JSON.parse(form.submittedvalues) as DynamicFieldsApiResponse;
      setDynamicFields(parsedValues);
    } catch (error) {
      console.error("Error parsing form values", error);
    } finally {
      setLoadingDynamicFields(false); // Set loader to false after parsing and setting dynamic fields
    }
  };

  const renderDynamicFields = () => {
    if (loadingDynamicFields) {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50%" }}>
          <ProgressSpinner />
        </div>
      );
    }

    return Object.keys(dynamicFields).map((key) => {
      const { value, formfield } = dynamicFields[key];
      const displayValue = Array.isArray(value) ? value.join(", ") : value;

      return (
        <div key={key} className="view-popup-field" style={{ marginBottom: "1rem", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <label style={{ flex: 1, fontWeight: 'bold' }}>{formfield}:</label>
          <span style={{ flex: 2, marginLeft: "10px" }}>{displayValue}</span>
        </div>
      );
    });
  };

  // Custom header with title and close button
  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{title}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={() => {
          onHide(); // Calls the onHide function to close the dialog
        }}
        aria-label="Close"
      />
    </div>
  );

  return (
    <Dialog
      className="popup popup-card-layout"
      visible={visible}
      draggable={false}
      style={{ width: "70vw" }}
      header={header}
      modal
      onHide={onHide}
    >
      <div className="card-wrapper">
        {/* Dropdown to select a form */}
        <div className="form-select">
          {loadingForms ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <ProgressSpinner style={{ width: '30px', height: '30px' }} />
            </div>
          ) : (
            <Dropdown
              value={selectedForm}
              options={forms}
              onChange={(e) => onFormSelect(e.value)}
              optionLabel="formname"
              placeholder="Select a form"
              style={{ width: "100%" }} // Ensures dropdown takes full width
            />
          )}
        </div>

        {/* Line gap between dropdown and dynamic fields */}
        <div style={{ margin: "1rem 0" }} />

        {/* Display the selected form's details */}
        {selectedForm && loadingDynamicFields ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50%" }}>
            <ProgressSpinner style={{ width: '30px', height: '30px' }} />
          </div>
        ) : selectedForm ? (
          <div className="form-details">
            {Object.keys(dynamicFields).length > 0 ? (
              renderDynamicFields()
            ) : (
              <div>No details available for this form.</div>
            )}
          </div>
        ) : (
          <div>Please select a form to view its details.</div>
        )}
      </div>

      {/* Close button */}
      <div className="cancel-submit-btn">
        <Button label="Close" className="cancel-btn" onClick={onHide} tabIndex={3} />
      </div>
    </Dialog>
  );
};

export default ViewDetailsPopup;
