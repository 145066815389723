import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import formServices from "../../services/formServices";
 
interface ViewFormPopupProps {
  visible: boolean;
  formId: number;
  setVisible: (visible: boolean) => void;
  setisView: (visible: boolean) => void;
}
 
const ViewFormPopup = (props: ViewFormPopupProps) => {
  const { visible, setVisible, formId, setisView } = props;
  const [dynamicFields, setDynamicFields] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({}); // Empty values for form fields
 
  const handleClose = () => {
    setVisible(false);
    setisView(false);
    setDynamicFields({});
  };
 
  useEffect(() => {
    if (visible) {
      fetchSubmittedFormDetails(formId);
    }
  }, [visible]);
 
  const fetchSubmittedFormDetails = async (formId: number) => {
    setLoading(true);
    try {
      const response = await formServices.EditForm(formId);
 
      if (response.status === 200) {
        const fieldsData = response.data;
        setDynamicFields(fieldsData);
        // Reset form values to empty
        const emptyValues = Object.keys(fieldsData).reduce((acc: any, key) => {
          acc[key] = "";
          return acc;
        }, {});
        setFormValues(emptyValues);
      } else {
        console.error("Failed to fetch form details", response);
      }
    } catch (error) {
      console.error("Error fetching form details", error);
    } finally {
      setLoading(false);
    }
  };
 
  const handleInputChange = (key: string, value: any) => {
    setFormValues({ ...formValues, [key]: value });
  };
 
  const renderDynamicFields = () => {
    return Object.keys(dynamicFields).map((key) => {
      const { formfield, fieldtype, fieldlabel, dropdown, checkbox } =
        dynamicFields[key];
      const value = formValues[key];
 
      return (
        <div
          key={key}
          className="view-popup-field"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <label htmlFor={key} style={{ width: "150px" }}>
            {formfield}
          </label>
 
          {fieldtype === "dropdown" && dropdown?.length ? (
            <Dropdown
              id={`${key}-dropdown`}
              value={value || ""}
              options={dropdown.map((option: string) => ({
                label: option,
                value: option,
              }))}
              onChange={(e) => handleInputChange(key, e.value)}
              placeholder={fieldlabel}
              style={{ width: "100%", flex: 1 }}
            />
          ) : fieldtype === "checkbox" && checkbox?.length ? (
            <MultiSelect
              id={`${key}-checkbox`}
              value={Array.isArray(value) ? value : []}
              options={checkbox.map((option: string) => ({
                label: option,
                value: option,
              }))}
              onChange={(e) => handleInputChange(key, e.value)}
              placeholder={fieldlabel}
              style={{ width: "100%", flex: 1 }}
            />
          ) : (
            <InputText
              id={key}
              value={typeof value === "string" ? value : ""}
              onChange={(e) => handleInputChange(key, e.target.value)}
              placeholder={fieldlabel}
              style={{ width: "100%", flex: 1 }}
            />
          )}
        </div>
      );
    });
  };
 
  return (
    <Dialog
      className="popup popup-card-layout"
      visible={visible}
      draggable={false}
      style={{ width: "70vw" }}
      header={
        <div className="p-d-flex p-jc-between p-ai-center">
          <span>View Form Details</span>
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text popup-close-button"
            onClick={handleClose}
            aria-label="Close"
          />
        </div>
      }
      modal
      onHide={handleClose}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <ProgressSpinner />
        </div>
      ) : (
        <div className="p-fluid">
          <div className="form-card">{renderDynamicFields()}</div>
        </div>
      )}
      <div className="cancel-submit-btn mt-8">
        <Button
          label="Close"
          className="cancel-btn"
          onClick={handleClose}
          tabIndex={3}
        />
      </div>
    </Dialog>
  );
};
 
export default ViewFormPopup;