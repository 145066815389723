import { useContext, useEffect, useState, useRef } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import AddFormPopup from "./AddFormPopup"; // Updated import
import { Toast } from "primereact/toast";
import FormTable from "./FormTable";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";

const FormLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [addFormChange, setAddFormChange] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [addNewFormPopup, setAddNewFormPopup] = useState(false); // Control visibility of popup
  const [isEditForm, setIsEditForm] = useState<any>(null); // Set as null initially
  const [editMode, setEditMode] = useState(false); // True when editing an existing form
  const toast = useRef<Toast>(null);
  const [reloadFormGrid, setReloadFormGrid] = useState(false); // Reload grid after submit
  const [searchQuery, setSearchQuery] = useState("");
  const [formDataFilter, setFormDataFilter] = useState([]); // State for filtered data
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);
  const [showSubmitButton, setShowSubmitButton] = useState(false); // State to control Submit button visibility

  const cardControls = [
    {
      ctrlType: "button",
      name: "btnAdd",
      title: resource.addNewForm,
      type: "button",
      disabled: false,
      className: "green-btn mr-2",
    },
  ];

  // Button click handler (for Add button)
  const onButtonClick = (e: string) => {
    if (e === "btnAdd") {
      setAddFormChange(true);
      setAddNewFormPopup(true); // Show Add form popup
      setEditMode(false); // New form, not editing
    } else if (e === "btnAddSubArea") {
      setAddFormChange(true);
      setEditMode(false); // New form for subarea, not editing
    }
  };

  // Back button handler
  const onBackButtonClick = () => {
    setBackButton(!backButton);
  };

  // Handle the Edit button click from the FormTable
  const onEditFormClicked = (data: any) => {
    setAddNewFormPopup(true); // Show AddFormPopup
    setIsEditForm(data); // Pass selected form data to the popup
    setEditMode(true); // Set edit mode to true
    setShowSubmitButton(data.view)
    
  };

  // Search functionality
  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  // Filtering form data based on search query
  const filteredFormData = formDataFilter?.filter((formDataFilter: any) => {
    return (
      formDataFilter?.p_formname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      formDataFilter?.p_description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Effect to remove the loading spinner after initial load
  useEffect(() => {
    setTimeout(() => {
      setLoadingSpinnerLayout(false);
    }, 400);
  }, []);

  return (
    <div className="ml-2">
      <Toast ref={toast} />
      {loadingSpinnerLayout ? (
        <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
      ) : (
        <>
          <div>
            {addNewFormPopup && (
              <AddFormPopup
                isEditForm={isEditForm} // Pass the form data to the popup
                addNewFormPopup={addNewFormPopup} // Control popup visibility
                setAddNewFormPopup={setAddNewFormPopup}
                editMode={editMode} // Pass edit mode state
                toast={toast}
                setReloadFormGrid={setReloadFormGrid}
                reloadFormGrid={reloadFormGrid}
                showSubmitButton={showSubmitButton} // Pass it down as a prop
              />
            )}

            <HeaderWithBackButton
              title={!addFormChange || backButton ? resource.formDetails : resource.formDetails}
              controls={cardControls}
              onButtonClicked={onButtonClick}
              onBackButtonClick={onBackButtonClick}
              handleSearch={handleSearch}
              searchQuery={searchQuery}
            />
            <div className="transparant-table">
              <div className="row flex">
                <div className="col-12">
                  <FormTable
                    onEditFormClicked={onEditFormClicked} // Pass edit handler to FormTable
                    addNewFormPopup={addNewFormPopup} // Updated prop
                    setAddNewFormPopup={setAddNewFormPopup} // Updated prop
                    reloadFormGrid={reloadFormGrid}
                    filteredFormData={filteredFormData}
                    setFormDataFilter={setFormDataFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FormLayout;
