import { MegaMenu } from "primereact/megamenu";
import { useContext, useEffect, useRef, useState } from "react";
import { MenuItem } from 'primereact/menuitem';
import RoleSetting from "./RoleSetting";
import UploadComponent from "./UploadComponent";
import ActivityLayout from "../activity/ActivityLayout";
import ExpertiesLayout from "../experties/ExpertiesLay";
import SitumLayout from "./Situm/SitumLayout";
import TherapLayout from "../therapy/TherapyLayout";
import FormLayout from "../form/FormLayout";

const SettingMenus = () => {
  const [activeItem, setActiveItem] = useState<string | null>('Role setting'); // set default active item<MegaMenu model={items} className="setting-menus" orientation="vertical" breakpoint="960px" />

  const items: MenuItem[] = [
    {
      label: 'Role setting',
      command: () => handleItemClick('Role setting'),
      className: activeItem === 'Role setting' ? 'active' : '', // add active class
    },
    {
      label: 'Activity setting',
      command: () => handleItemClick('Activity setting'),
      className: activeItem === 'Activity setting' ? 'active' : '', // add active class
    },
    {
      label: 'Therapy setting',
      command: () => handleItemClick('Therapy setting'),
      className: activeItem === 'Therapy setting' ? 'active' : '', // add active class
    },
    {
      label: 'Expert setting',
      command: () => handleItemClick('Expert setting'),
      className: activeItem === 'Expert setting' ? 'active' : '', 
    },
    {
      label: 'Sync POI',
      command: () => handleItemClick('Sync POI'),
      className: activeItem === 'Sync POI' ? 'active' : '', 
    },
    {
      label: 'Form setting',
      command: () => handleItemClick('Form setting'),
      className: activeItem === 'Form setting' ? 'active' : '', // add active class
    }
  ];

  const handleItemClick = (label: string) => {
    setActiveItem(label);
  };

  return (
    <div className="container">
      <div className="flex">
        <div className="p-0">
        <MegaMenu model={items} className="setting-menus" orientation="vertical" breakpoint="960px" />    
             </div>
        <div className="w-full">
          {activeItem === 'Role setting' && <RoleSetting />}
          {activeItem === 'Activity setting' && <ActivityLayout />}
          {activeItem === 'Therapy setting' && <TherapLayout />}

          {activeItem === 'Expert setting' && <ExpertiesLayout />}
          {activeItem === 'Sync POI' && <SitumLayout />}
          {activeItem === 'Form setting' && <FormLayout />}

        </div>
      </div>
    </div>
  );
};

export default SettingMenus;