import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState, useRef } from "react";
import { contextStore } from "../../context/CustomStateProvider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import formServices from "../../services/formServices";
import AddNewFormValidation from "../../validation/AddNewFormValidation";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";

interface AddFormValues {
  FormName: string;
  Description: string;
  Status: boolean;
  FormFields: Array<{
    formField: string;
    fieldType: string;
    label: string;
    value: string;
    options: string[]; // Added to handle dropdown or checkbox values
  }>;
}

const AddFormPopup = ({
  addNewFormPopup,
  setAddNewFormPopup,
  editMode,
  isEditForm,
  setReloadFormGrid,
  toast,
  showSubmitButton, // Add this prop to control visibility
  ...props
}: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [formFields, setFormFields] = useState<any[]>([]); // Options for form field types
  const [submitClick, setSubmitClick] = useState(false);
  const [edituserData, setEditUserData] = useState<any>(null);
  const inputRef = useRef<HTMLInputElement>(null); // Input ref
  const [firstFieldTouched, setFirstFieldTouched] = useState(false); // Track if the first field is touched

  const initialValues: AddFormValues = {
    FormName: "",
    Description: "",
    Status: true, // Default to true for active status
    FormFields: [
      {
        formField: "",
        fieldType: "", // Default field type
        label: "",
        value: "",
        options: [], // Initialize empty options
      },
    ],
  };

  // Set form values for editing
  const OnEditData = () => {
    if (edituserData) {
      setTimeout(() => {
        formik.setValues({
          FormName: edituserData.p_formname || "",
          Description: edituserData.p_description || "",
          Status: edituserData.p_status,
          FormFields: edituserData.p_formfields || [
            {
              formField: "",
              fieldType: "",
              label: "",
              value: "",
              options: [],
            },
          ],
        });
      }, 20);
    }
  };

  // Check if at least one field is filled out
  const hasAtLeastOneField = (values: AddFormValues) => {
    return values.FormFields.some(
      (field) => field.formField.trim() !== "" || field.label.trim() !== ""
    );
  };
  const handleSubmit = async (values: AddFormValues) => {
    setSubmitClick(true);

    if (!hasAtLeastOneField(values)) {
      // Show a warning message if no field is filled out
      toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "At least one form field is required.",
        life: 3000,
      });
      setSubmitClick(false); // Reset submit click flag
      return; // Prevent submission if no fields are filled out
    }
    // Check form validity before sending the data
    if (formik.isValid) {
      // Construct the form attributes object
      const formAttributes: Record<string, any> = {};

      // Iterate through the form fields and structure them as required
      values.FormFields.forEach((field) => {
        let fieldData: any = {
          formfield: field.formField,
          fieldtype: field.fieldType,
          fieldlabel: field.label,
        };

        // Handle dropdown field type
        if (field.fieldType === "dropdown") {
          fieldData.dropdown = field.options; // Store options in dropdown
        } else if (field.fieldType === "checkbox") {
          fieldData.checkbox = field.options; // Store options for checkbox
        }
        // Add this field to the formAttributes object using its field name as the key
        formAttributes[field.formField] = fieldData;
      });

      // Convert the formAttributes object into a JSON string
      const formattedAttributes = JSON.stringify(formAttributes);

      // Construct the final payload
      const data = {
        formname: values.FormName,
        description: values.Description,
        formattributes: formattedAttributes, // Attach the JSON string here
        status: values.Status,
      };

      // Log the final payload to be sent
      console.log(
        "Form data to be sent as JSON:",
        JSON.stringify(data, null, 2)
      );

      setTimeout(async () => {
        // If in create mode
        if (!editMode) {
          await AddNewForm(data); // Pass data directly to AddNewForm
        } else {
          await UpdateForm(data); // Pass data directly to UpdateForm
        }

        setSubmitClick(false);
      }, 4000);
    }
  };

  const AddNewForm = async (data: any) => {
    formServices.AddForm(data).then((res: any) => {
      if (res.status === 200) {
        if (res.data.p_response) {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: res.data.p_msg,
            life: 3000,
          });

          setReloadFormGrid((prev: any) => !prev); // Refresh the grid
        } else {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.data.p_msg,
            life: 3000,
          });
        }
        setAddNewFormPopup(false); // Close the popup after form submission
      }
    });
  };

  const UpdateForm = async (values: any) => {
    values["formid"] = edituserData.p_formid;

    formServices.UpdateForm(values).then((res: any) => {
      if (res.status === 200) {
        if (res.data.p_response) {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: res.data.p_msg,
            life: 3000,
          });
          setReloadFormGrid((prev: any) => !prev); // Refresh the grid
        } else {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.data.p_msg,
            life: 3000,
          });
        }

        setAddNewFormPopup(false); // Close the popup after form submission
      }
    });
  };

  const onCancelClick = () => {
    formik.resetForm();
    setAddNewFormPopup(false); // Close the popup on cancel
  };

  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{resource.addNewFormHeader}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={() => {
          setAddNewFormPopup(false); // Close the popup on close button click
          onCancelClick();
        }}
      />
    </div>
  );

  useEffect(() => {
    setEditUserData(isEditForm);
  }, [isEditForm]);

  useEffect(() => {
    if (editMode) {
      setTimeout(() => {
        OnEditData();
      }, 20);
    }
  }, [edituserData]);

  useEffect(() => {
    if (addNewFormPopup) {
      setFormFields([
        { label: "Text Field", value: "text" },
        { label: "Dropdown", value: "dropdown" },
        { label: "Checkbox", value: "checkbox" },
      ]);
    }
  }, [addNewFormPopup]);

  const addFormField = () => {
    formik.setFieldValue("FormFields", [
      ...formik.values.FormFields,
      { formField: "", fieldType: "", label: "", value: "", options: [] }, // Empty options for now
    ]);
  };

  const removeFormField = (index: number) => {
    if (formik.values.FormFields.length > 1) {
      const updatedFields = [...formik.values.FormFields];
      updatedFields.splice(index, 1); // Remove the field at the specified index
      formik.setFieldValue("FormFields", updatedFields);
      formik.validateForm(); // Trigger validation after removing the field
      formik.setTouched({}); // Ensure no fields are marked as touched to avoid automatic submission
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewFormValidation,
    onSubmit: handleSubmit,
    enableReinitialize: true, // Ensure that form values are updated on changes
  });

  useEffect(() => {
    if (formik.values.FormFields[0]?.formField.trim()) {
      setFirstFieldTouched(true);
    } else {
      setFirstFieldTouched(false);
    }
  }, [formik.values.FormFields]);

  return (
    <Dialog
      className="popup popup-card-layout"
      header={header}
      draggable={false}
      visible={addNewFormPopup} // Now using destructured addNewFormPopup
      style={{ width: "70vw" }}
      onHide={() => {
        setAddNewFormPopup(false); // Close the popup
        onCancelClick();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="card-wrapper">
          <div className="flex mb-2">
            <div className="col-6 relative">
              <label
                htmlFor="FormName"
                className="block text-900 font-medium mb-2"
              >
                Form name {resource.formName}{" "}
                <span className="text-red">*</span>
              </label>
              <InputText
                id="FormName"
                placeholder="Enter form name"
                className="w-full mb-2"
                value={formik.values.FormName}
                onChange={formik.handleChange}
                ref={inputRef} // Reference inputRef
              />
              {formik.errors.FormName && formik.touched.FormName && (
                <div className="text-red-500 text-xs">
                  {formik.errors.FormName}
                </div>
              )}
            </div>

            <div className="col-6 relative">
              <label
                htmlFor="description"
                className="block text-900 font-medium mb-2"
              >
                 {resource.description}
              </label>
              <InputTextarea
                id="Description"
                placeholder="Enter description"
                className="w-full mb-2"
                rows={3}
                value={formik.values.Description}
                onChange={formik.handleChange}
                style={{ resize: "none" }}
              />
              {formik.errors.Description && formik.touched.Description && (
                <div className="text-red-500 text-xs">
                  {formik.errors.Description}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card-wrapper">
          <div className="flex align-items-center">
            <div className="col-12 relative">
              <label className="form-fields-label block text-900 font-semibold mb-2">
                Form fields
              </label>
              {formik.values.FormFields.map((field, index) => (
                <div key={index} className="flex mb-2">
                  <div className="col-3">
                    <label
                      htmlFor={`FormField-${index}`}
                      className="block text-900 font-medium mb-2"
                    >
                      Field name <span className="text-red">*</span>
                    </label>
                    <InputText
                      id={`FormField-${index}`}
                      placeholder="Enter field name"
                      className="w-full"
                      value={field.formField}
                      onChange={(e) => {
                        const updatedFields = [...formik.values.FormFields];
                        updatedFields[index].formField = e.target.value;
                        formik.setFieldValue("FormFields", updatedFields);
                      }}
                    />
                    {formik.errors.FormFields?.[index] &&
                      typeof formik.errors.FormFields[index] !== "string" &&
                      formik.touched.FormFields?.[index]?.formField && (
                        <div className="text-red-500 text-xs mt-5">
                          {(formik.errors.FormFields[index] as any).formField}
                        </div>
                      )}
                  </div>

                  <div className="col-3">
                    <label
                      htmlFor={`FieldType-${index}`}
                      className="block text-900 font-medium mb-2"
                    >
                      Field type <span className="text-red">*</span>
                    </label>
                    <Dropdown
                      id={`FieldType-${index}`}
                      value={field.fieldType}
                      options={formFields}
                      onChange={(e) => {
                        const updatedFields = [...formik.values.FormFields];
                        updatedFields[index].fieldType = e.value;
                        updatedFields[index].options = []; // Reset options on field type change
                        formik.setFieldValue("FormFields", updatedFields);
                      }}
                      placeholder="Select field type"
                    />
                  </div>

                  <div className="col-3">
                    <label
                      htmlFor={`Label-${index}`}
                      className="block text-900 font-medium mb-2"
                    >
                      Label name <span className="text-red">*</span>
                    </label>
                    <InputText
                      id={`Label-${index}`}
                      placeholder="Enter label"
                      className="w-full"
                      value={field.label}
                      onChange={(e) => {
                        const updatedFields = [...formik.values.FormFields];
                        updatedFields[index].label = e.target.value;
                        formik.setFieldValue("FormFields", updatedFields);
                      }}
                    />
                    {/* Validation Error for label */}
                    {formik.errors.FormFields?.[index] &&
                      typeof formik.errors.FormFields[index] !== "string" &&
                      formik.touched.FormFields?.[index]?.label && (
                        <div className="text-red-500 text-xs mt-5">
                          {(formik.errors.FormFields[index] as any).label}
                        </div>
                      )}
                  </div>

                  {(field.fieldType === "dropdown" ||
                    field.fieldType === "checkbox") && (
                    <div className="col-3">
                      <label
                        htmlFor={`Value-${index}`}
                        className="block text-900 font-medium mb-2"
                      >
                        Options <span className="text-red">*</span>
                      </label>
                      <InputText
                        id={`Value-${index}`}
                        placeholder="Enter options"
                        className="w-full"
                        value={field.options ? field.options.join(", ") : ""} // Safe check
                        // value={field.options.join(", ")} // Join the options as comma-separated
                        onChange={(e) => {
                          const updatedFields = [...formik.values.FormFields];
                          updatedFields[index].options = e.target.value
                            .split(",")
                            .map((option) => option.trim()); // Split by commas and trim
                          formik.setFieldValue("FormFields", updatedFields);
                        }}
                      />
                    </div>
                  )}

                  {formik.values.FormFields.length > 1 && (
                    <div>
                      <Button
                        icon=""
                        className="table-button custome-icon delete-icon m-0"
                        onClick={() => removeFormField(index)}
                      />
                    </div>
                  )}
                </div>
              ))}
              <div className="col-12 mt-2 flex justify-content-end">
                <Button
                  icon="pi pi-plus"
                  className="w-2rem h-2rem p-0 no-margin"
                  style={{ color: "black", border: "2px solid #555555" }}
                  rounded
                  type="button"
                  onClick={addFormField}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card-wrapper">
          <div className="flex">
            <div className="col-6">
              <label
                htmlFor="Status"
                className="block text-900 font-medium mb-2"
              >
                Status
              </label>
              <InputSwitch
                id="Status"
                checked={formik.values.Status}
                onChange={(e) => formik.setFieldValue("Status", e.value)}
              />
            </div>
          </div>
        </div>

        <div className="cancel-submit-btn mt-8">
          <Button
            label="Cancel"
            className="cancel-btn"
            onClick={onCancelClick}
            type="reset"
          />

          <Button
            label="Submit"
            className="submit-btn ml-2"
            type="submit"
            loading={submitClick}
            disabled={
              submitClick ||
              formik.values.FormFields.length < 1 ||
              !formik.isValid ||
              !hasAtLeastOneField(formik.values)
            }
          />
        </div>
      </form>
    </Dialog>
  );
};

export default AddFormPopup;
