import * as Yup from 'yup';

const AddNewFormValidation = Yup.object().shape({
  FormName: Yup.string()
    .required('Form Name is required')
    .min(3, 'Form Name should be at least 3 characters long'),
    
  Description: Yup.string()
    .optional()
    .max(500, 'Description should not exceed 500 characters'),
    
  Status: Yup.boolean().required('Status is required'),  // Status should be boolean and required
  
  FormFields: Yup.array()
    .of(
      Yup.object().shape({
        formField: Yup.string()
          .required('Field Name is required')
          .min(3, 'Field Name should be at least 3 characters long'),
        
        fieldType: Yup.string()
          .required('Field Type is required')
          .oneOf(['text', 'dropdown', 'checkbox', 'date'], 'Invalid field type'), // Adjust field types as needed
        
        label: Yup.string()
          .required('Label is required')
          .min(3, 'Label should be at least 3 characters long'),
        
        value: Yup.string().optional(), // Optional string for the value (depending on field type)
        
        options: Yup.array()
          .of(Yup.string())
          .when('fieldType',  ([fieldType]) => {
            return fieldType === 'dropdown' || fieldType === 'checkbox' ? Yup.array().min(1, 'At least one option is required for dropdown/checkbox')
            .required('Options are required for dropdown/checkbox') : Yup.array().notRequired();
          })
      })
    )
    .min(1, 'At least one form field is required')
    .required('Form Fields are required')
});

export default AddNewFormValidation;
